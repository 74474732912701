import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import Footer from '../components/Shared/Footer'
import Header from '../components/Shared/Header'
import Navigation from '../components/Shared/Navigation'
import Modals from '../components/Shared/Modals'
import WelcomeModal from '../components/Shared/WelcomeModal'
import 'swiper/swiper.min.css'
import 'animate.css/animate.min.css'
import '../styles/layout.css'
import { startIntercomPolling } from '../utils/format';

const isProd = process.env.NODE_ENV === 'production'
const branchName = process.env.BRANCH

const Layout = ({
  children,
  headerClasses,
  customHero,
  heroBodyStyle,
  heroBodyClasses,
  heroContainerId = 'maximusHeroID',
  data = null,
  lightMode = false,
  headerDisable = false,
  footerDisable = false,
  signUpUrl = null,
  signUpText = null,
  signInHide = false,
  navStatic = false,
  themeColor = '#000000'
}) => {
  const [topAdBannerOffset, setTopAdBannerOffset] = useState(0)

  const checkTopAdBannerOffset = () => {
    const topBar = document.querySelector('.top-bar')
    if (topBar) {
      setTopAdBannerOffset(topBar.offsetHeight)
    }
  }

  useEffect(() => {
    checkTopAdBannerOffset()

    if (!isProd) {
      return
    }

    analytics.ready(() => {
      const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 0 })
      amplitude.add(sessionReplayTracking)
      amplitude.init('1383a0c7c8681f872dca9e3b450439f5', null, { 
        autocapture: true,
        deviceId: analytics.user().anonymousId() 
      })

      startIntercomPolling()
    })
  }, [])

  if (typeof window !== 'undefined' && window.localStorage && window.location.search) {
    window.localStorage.setItem('searchParams', window.location.search)
  }

  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="iya5akpfz91rabm8tmn69esd7mz8vi" />
        <meta name="google-site-verification" content="CGren4xLhQQQSz6eDZ0YcVtnBFR_Xw1xiKgfwDNVbTY" />
        <meta name="theme-color" content={themeColor} />

        {data.seo?.noIndex && <meta name="robots" content="noindex" />}

        {isProd && <script type="text/javascript" src="https://cdn.amplitude.com/script/1383a0c7c8681f872dca9e3b450439f5.experiment.js" />}
        {isProd && 
          <script type="text/javascript">
            {`
              analytics.track('segmentUserIdentify', { user_id: '', email_hash: '' });
              analytics.track('pageview', {
                'Branch': '${branchName}'
              });
            `}
          </script>
        }
      </Helmet>

      {!headerDisable && data.settings && data.settings.navigation && (
        <Navigation
          navigation={data.settings.navigation}
          topBar={data.pageSettings.topAdBanner}
          signUpUrl={signUpUrl}
          signUpText={signUpText}
          signInHide={signInHide}
          navStatic={navStatic}
        />
      )}

      {(data.hero || data.contacts || customHero) && !lightMode && (
        <Header
          classes={headerClasses}
          hero={data.hero}
          customHero={customHero}
          getStartedNowUrl={data.settings ? data.settings.getStartedNowUrl : ''}
          contacts={data.contacts}
          heroBodyStyle={heroBodyStyle}
          heroBodyClasses={heroBodyClasses}
          heroContainerId={heroContainerId}
        />
      )}

      <main style={{ paddingTop: topAdBannerOffset }}>{children}</main>

      {!footerDisable && data.settings && data.settings.footer && <Footer footer={data.settings.footer} />}

      <Modals />

      {data.settings?.welcomePopup?.enable && <WelcomeModal data={data.settings.welcomePopup} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
