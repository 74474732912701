import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { PopupButton } from '@typeform/embed-react'
import { Link } from 'gatsby'
import { useScrollDirection } from 'react-use-scroll-direction'
import { prepareParagraph, sanityImageUrl, urlWithSearchParamsHandler, clickTrack } from '../../../utils/format'
import styles from './style.module.sass'

const TopBar = ({ content }) => {
  if (!content) {
    return null
  }

  useEffect(() => {
    document.body.classList.add('top-bar-visible')
    return () => {
      document.body.classList.remove('top-bar-visible')
    }
  }, [])

  return (
    <div className="top-bar">
      <div className="top-bar__content">{prepareParagraph(content)}</div>
    </div>
  )
}

const SignUpButton = ({ url, label, setMobileOpen }) => {
  const checkUrl = () => {
    if (url === '/#protocols' || url === '/#reviews') {
      document.body.classList.remove(styles.mobileOpened)
      setMobileOpen(false)
    }
  }

  if (url.includes('https://quiz/')) {
    return (
      <PopupButton
        id={url.replace('https://quiz/', '')}
        as="a"
        onReady={() => clickTrack(label)}
        transitiveSearchParams={true}
        className={styles.btnsSignUp}
        buttonProps={{ id: 'btn-action' }}
      >
        {label}
      </PopupButton>
    )
  }

  if (url.includes('https://')) {
    return (
      <a href={url} id="btn-action" onClick={urlWithSearchParamsHandler} className={styles.btnsSignUp}>{label}</a>
    )
  }

  return (
    <Link to={url} id="btn-action" onClick={checkUrl} className={styles.btnsSignUp}>{label}</Link>
  )
}

const SmartLink = ({ item }) => {  
  if (item.href.includes('https://')) {
    return (
      <a href={item.href} onClick={urlWithSearchParamsHandler}><span className={styles.lnk}>{item.title}</span>{item.tag && <span className={styles.tag}>{item.tag}</span>}</a>
    )
  }

  return (
    <Link to={item.href}><span className={styles.lnk}>{item.title}</span>{item.tag && <span className={styles.tag}>{item.tag}</span>}</Link>
  )  
}

const NavComponent = ({ item }) => (
  <>
    {item._type === 'banner' && (
      <div className={styles.banner}>
        <p className={styles.bannerTitle} dangerouslySetInnerHTML={{ __html: item.title }} />
        <SmartLink item={{ title: item.buttonText, href: item.buttonUrl }} />
        <img className={styles.bannerImage} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
      </div>
    )}

    {item._type === 'column' && (
      <div className={styles.list}>
        <p className={styles.listTitle}>{item.title}</p>
        <ul className={styles.listItems}>
          {item.links && item.links.map(link => (
            <li className={styles.listItem} key={link._key}>
              <SmartLink item={link} />
            </li>
          ))}
        </ul>
      </div>
    )}

    {item._type === 'viewAllLink' && (
      <div className={styles.viewAll}>
        <Link to={item.buttonUrl}>{item.buttonText}</Link>
      </div>
    )}
  </>
)

const DropdownColumns = ({ item, desktop }) => (
  <div className={cx(styles.columns, {
    [styles.columnsRight]: item.insideRightAlignment,
  })}>
    {item.inside.filter(column => !(desktop && column.mobileOnly)).filter(column => !(!desktop && column._type === 'banner')).map(column => (
      <div className={cx(styles.column, {
        [styles.columnViewAll]: column._type === 'viewAllLink'
      })} key={column._key}>
        <NavComponent item={column} />
      </div>
    ))}
  </div>
)

export default ({ navigation, signUpUrl, signUpText, signInHide, topBar, navStatic }) => {
  const navRef = useRef()
  const direction = useScrollDirection()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [scrollBefore, setScrollBefore] = useState(0)

  const scrollHandler = () => {
    const scroll = document.documentElement.scrollTop

    if (scroll > 0) {
      navRef.current.classList.add(styles.navFixed)
    } else {
      navRef.current.classList.remove(styles.navFixed)
      navRef.current.classList.remove(styles.navHidden)
      navRef.current.classList.remove(styles.navVisible)
    }

    if (direction.isScrollingDown) {
      navRef.current.classList.add(styles.navHidden)
      navRef.current.classList.remove(styles.navVisible)
    }

    if (direction.isScrollingUp) {
      navRef.current.classList.remove(styles.navHidden)
      navRef.current.classList.add(styles.navVisible)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [direction])

  const mobileMenuHandler = () => {
    if (mobileOpen) {
      document.body.classList.remove(styles.mobileOpened)
      document.documentElement.scrollTop = scrollBefore
    } else {
      setScrollBefore(document.documentElement.scrollTop)
      document.body.classList.add(styles.mobileOpened)
      document.getElementsByClassName(styles.mobile)[0].scrollTop = 0
    }

    setMobileOpen(!mobileOpen)
  }

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    appHeight()
    window.addEventListener('resize', appHeight)
    document.body.classList.remove(styles.mobileOpened)
    return () => {
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  const signUpButtonUrl = signUpUrl ? signUpUrl : navigation.signUpUrl
  const signUpButtonLabel = signUpText ? signUpText : navigation.signUpText

  return (
    <div id="nav" className={cx(styles.nav, {
      [styles.navStatic]: navStatic
    })} ref={navRef}>
      <TopBar content={topBar} />
      <div className={styles.wrap}>
        <Link to="/" className={styles.logo} role="navigation" aria-label="Home page" />

        <ul className={styles.links}>
          {navigation.links &&
            navigation.links.map(item => (
              <li key={item._key} className={styles.link}>
                {item.inside ? (
                  <>
                    <span className={styles.dropdownTitle}>{item.title}</span>
                    <div className={styles.dropdown}>
                      <div className={styles.dropdownWrap}>
                        <DropdownColumns item={item} desktop={true} />
                      </div>
                    </div>
                  </>
                ) : (
                  <SmartLink item={item} />
                )}
              </li>
            ))}
        </ul>

        <div className={styles.btns}>
          {!signInHide && (
            <a href={navigation.signInUrl} onClick={urlWithSearchParamsHandler} className={styles.btnsSignIn}>
              {navigation.signInText}
            </a>
          )}

          <SignUpButton url={signUpButtonUrl} label={signUpButtonLabel} setMobileOpen={setMobileOpen} />

          <div
            className={cx(styles.mobileBtnNav, {
              [styles.mobileBtnNavClose]: mobileOpen,
            })}
            onClick={mobileMenuHandler}
          />
        </div>
      </div>

      <div className={styles.mobile}>
        <div className={styles.mobileWrap}>
          {navigation.mobileBanner && (
            <div className={styles.banner}>
              <p className={styles.bannerTitle} dangerouslySetInnerHTML={{ __html: navigation.mobileBanner?.title }} />
              <SmartLink item={{ title: navigation.mobileBanner?.buttonText, href: navigation.mobileBanner?.buttonUrl }} />
              <img className={styles.bannerImage} src={sanityImageUrl(navigation.mobileBanner?.image)} alt={navigation.mobileBanner?.image?.caption} />
            </div>
          )}

          <ul className={styles.mobileLinks}>
            {navigation.links &&
              navigation.links.filter(item => !item.desktopOnly).map(item => (
                <li key={item._key}className={styles.mobileLink}>
                  {item.inside ? (
                    <>
                      <span className={styles.dropdownTitle}>{item.title}</span>
                      <div className={styles.dropdown}>
                        <div className={styles.dropdownWrap}>
                          <DropdownColumns item={item} desktop={false} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <SmartLink item={item} />
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
